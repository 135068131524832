import {colors} from './colors'

export default function sentReceived (qualtricsData) {
  return {
    canvasId: 'sentReceived',
    chart: {
      type: 'bar',
      data: {
        labels: ['Faculty', 'Staff-Salaried', 'Seniors', 'Juniors', 'Sophomores', 'Freshman', 'Staff-Hourly'],
        datasets: [
          {
            label: 'Emails Received per 1 Email Sent',
            data: [1.4, 2.4, 5.2, 6.9, 7.2, 8.0, 30],
            backgroundColor: [
              colors.mint.color,
              colors.purple.color,
              colors.fuchsia.color,
              colors.fuchsia.color,
              colors.fuchsia.color,
              colors.fuchsia.color,
              colors.purple.color,
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        aspectRatio: 1.2,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  }


}
