import 'regenerator-runtime/runtime'
import initCharts from './charts'
import Typewriter from 'typewriter-effect/dist/core'
import {colors as chartColors} from './charts/colors.js'

const chartArray = [];

Chart.defaults.global.defaultFontColor = 'white';
Chart.defaults.global.defaultFontFamily = '"Roboto Condensed", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
Chart.defaults.global.defaultFontSize = 14;

(async function () {
  const response = await fetch('https://raw.githubusercontent.com/DavidsonCollege/eCDN/master/comms-data/data.json')

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    const qualtricsData = await response.json();
    console.log(qualtricsData)
    const overallResponses = qualtricsData.find(i => i.name === 'overallExperience')
    const overallStrings =  overallResponses.values.sort((a,b) => a.count > b.count ? -1 : 1).slice(0,4).map(i => i.label)
    return {
      charts: initCharts(qualtricsData),
      overallStrings
    }
  }
})()
  .then(response => {
    // https://github.com/tameemsafi/typewriterjs
    if (document.getElementById('typewriter')) {
      new Typewriter('#typewriter', {
        strings: response.overallStrings,
        autoStart: true,
        loop: true
      });
    }

    for (const config of response.charts) {
      const ctx = document.getElementById(config.canvasId);
      if(ctx){
        chartArray.push(new Chart(ctx, config.chart));
      }
    }
  })
  .catch(e => {
    console.log('There has been a problem with your fetch operation: ' + e.message);
  });

function hideAnimation() {
  var x = document.getElementById("suggestions");
  var z = document.getElementById("hideAnimation");
  var y = document.getElementById("suggestionsStatic");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.display = "none";
  } else {
    x.style.display = "none";
    z.style.display="none";
    y.style.display = "block";
  }
}

function toggleColors(color){
  switch (color) {
    case chartColors.mint.color:
      return chartColors.mint.dark;
    case chartColors.mint.transparent:
      return chartColors.mint.darkTransparent;
    case chartColors.mint.dark:
      return chartColors.mint.color;
    case chartColors.mint.darkTransparent:
      return chartColors.mint.transparent;
    case chartColors.fuchsia.color:
      return chartColors.fuchsia.dark;
    case chartColors.fuchsia.transparent:
      return chartColors.fuchsia.darkTransparent;
    case chartColors.fuchsia.dark:
      return chartColors.fuchsia.color;
    case chartColors.fuchsia.darkTransparent:
      return chartColors.fuchsia.transparent;
    default:
      return color;
  }
}

function toggleAllColors(colorArrayOrString) {
  if(typeof colorArrayOrString === 'string'){
    return toggleColors(colorArrayOrString);
  }
  var newColors = [];
  for(let color of colorArrayOrString){
    newColors.push(toggleColors(color));
  }
  return newColors;
}

function toggleDatasetColors(dataset, colorSet){
  if(dataset[colorSet]){
    return toggleAllColors(dataset[colorSet]);
  }
  return false;
}


function toggleLightMode() {
  const element = document.body;
  element.classList.toggle("light-mode");
  const lightMode = element.classList.contains('light-mode')
  const text_color = lightMode ? '#121212' : 'white';
  const bg_color = lightMode ? 'white' : '#121212';

  Chart.defaults.global.defaultFontColor = text_color;

  for(let chart of chartArray) {
    for(const [index, dataset] of chart.data.datasets.entries()) {
      for(let colorSet of ['backgroundColor', 'borderColor']) {
        chart.data.datasets[index][colorSet] = toggleDatasetColors(dataset, colorSet);
      }
    }
    if(chart.config.type==="horizontalBar"){
      chart.options.scales.xAxes[0].gridLines.color = text_color;
      chart.options.scales.xAxes[0].gridLines.zeroLineColor = text_color;
    }
    if(chart.config.type==="radar"){
      chart.options.scale.gridLines.color = text_color;
      chart.options.scales.xAxes[0].gridLines.color = bg_color;
      chart.options.scales.yAxes[0].gridLines.color = bg_color;
      chart.options.scales.xAxes[0].gridLines.zeroLineColor = bg_color;
      chart.options.scales.yAxes[0].gridLines.zeroLineColor = bg_color;
      }
  //has to run update twice, otherwise labels are not updated
  chart.update();
  chart.update();

  }
}

document.addEventListener('click', function (event) {
  if (event.target.matches('#hideAnimation')) {
    event.preventDefault();
    hideAnimation();
  }
  if (event.target.matches('.toggleLightMode')) {
    event.preventDefault();
    toggleLightMode();
  }
  return;

}, false);
