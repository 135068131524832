import {colors} from './colors'

export default function emailsAmount (qualtricsData) {
  return {
    canvasId: 'emailsAmountActual',
    chart: {
      type: 'bar',
      data: {
        labels: ['Students', 'Faculty', 'Salaried Staff', 'Hourly Staff'],
        datasets: [
          {
            label: 'Internal Emails',
            data: [8, 12, 17, 4],
            backgroundColor: [
              colors.fuchsia.color,
              colors.mint.color,
              colors.purple.color,
              colors.neutral.color,
            ],
            borderWidth: 1
          },
          {
            label: 'External Emails',
            data: [5, 25, 21, 6],
            backgroundColor: [
              colors.fuchsia.transparent,
              colors.mint.transparent,
              colors.purple.transparent,
              colors.neutral.transparent,
            ],
            borderColor: [
              colors.fuchsia.color,
              colors.mint.color,
              colors.purple.color,
              colors.neutral.color,
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        aspectRatio: 1.2,
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [
            {
              stacked: true,
            }
          ]
        }
      }
    }
  }


}
