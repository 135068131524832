import insideDavidsonChart from './insideDavidsonChart'
import sentReceived from './sentReceived'
import appUsage from './appUsage'
import insideDavidsonEasyDifficult from './insideDavidsonEasyDifficult'
import insideDavidsonDataFreshness from './insideDavidsonDataFreshness'
import toolActivityUsage from './toolActivityUsage'
import surveyResponses from './surveyResponses'
import emailsAmount from './emailsAmount'
import emailsAmountActual from './emailsAmountActual'
import biggestChallenge from './biggestChallenge'
import overallCommunicationExperience from './overallCommunicationExperience'
import easeOfUseTool from "./easeOfUseTool";
import overallInfo from "./overallFindingInfoExperience";
import easeOfUseInfo from "./easeOfUseInfo";
import informationSource from "./informationSource";
import emailsRelevancy from "./emailsRelevancy";
import tooManyEmailsSource from "./tooManyEmailsSource";
import sufficientInfo from './sufficientInfo'
import anotherPlatform from "./anotherPlatform";
import emailTasks from "./emailTasks";
import searchVsNav from "./searchVsNav";
import collabPlatformEase from "./collabPlatformEase";
import infoBiggestChallenge from "./infoBiggestChallenge";
// import sufficientVsEmails from './sufficientVsEmails'

export default function initCharts (data) {
  return [
      insideDavidsonChart(data),
      sentReceived(data),
      appUsage(data),
      insideDavidsonEasyDifficult(data),
      insideDavidsonDataFreshness(data),
      toolActivityUsage(data),
      toolActivityUsage(data, 'student'),
      toolActivityUsage(data, 'faculty'),
      toolActivityUsage(data, 'staff'),
      surveyResponses(data),
      emailsAmount(data),
      emailsAmountActual(data),
      emailsRelevancy(data),
      biggestChallenge(data),
      overallCommunicationExperience(data),
      easeOfUseTool(data,'Email'),
      easeOfUseTool(data,'Zoom'),
      easeOfUseTool(data,'Texting'),
      easeOfUseTool(data,'Slack'),
      easeOfUseTool(data,'Microsoft Teams'),
      easeOfUseTool(data,'Google Hangouts'),
      easeOfUseInfo(data,'Academic Calendar'),
      easeOfUseInfo(data,'Dining Menus'),
      easeOfUseInfo(data,'College Policies'),
      easeOfUseInfo(data,'Room Reservation'),
      easeOfUseInfo(data,'Benefits'),
      easeOfUseInfo(data,'Future Courses'),
      easeOfUseInfo(data,'Events & Activities'),
      easeOfUseInfo(data,'Department Forms'),
      overallInfo(data),
      informationSource(data),
      informationSource(data, 'student'),
      informationSource(data, 'faculty'),
      informationSource(data, 'staff'),
      tooManyEmailsSource(data),
      sufficientInfo(data),
      anotherPlatform(data),
      emailTasks(data),
      searchVsNav(data),
      collabPlatformEase(data),
      infoBiggestChallenge(data)
    // sufficientVsEmails(data)
  ]
}
