import {colors} from './colors'
import {helpers} from "./helpers";

export default function easeOfUseEmail (qualtricsData, tool) {
  const responses = qualtricsData.find(i => i.name === 'toolDifficulty')
  const subResponse = responses.values.find(i => i.choice === tool)
  const naCount = helpers.bipolarNaCount(subResponse)
  return {
    canvasId: `easeOfUse${tool.replace(/ /g,'')}`,
    chart: {
      type: 'horizontalBar',
      data: {
        labels: [`Students`, 'Faculty', 'Staff'],
        datasets: [
          {
            label: 'Easy:Difficult',
            data: [
              helpers.bipolarize(subResponse, 'student'),
              helpers.bipolarize(subResponse, 'faculty'),
              helpers.bipolarize(subResponse, 'staff'),
            ],
            backgroundColor: [
              colors.fuchsia.color,
              colors.mint.color,
              colors.purple.color,
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        ...helpers.bipolarOptions(
            {
              right: 'Difficult',
              left: 'Easy',
              middle: 'Neutral'
            }
        ),
        ...{
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                const persona = tooltipItem.label === 'Students' ? 'student' : tooltipItem.label.toLowerCase()
                return `${helpers.bipolarCount(subResponse, persona)} responses`;
              }
            }
          },
          title: {
            display: true,
            text: `${naCount} N/A ${helpers.pluralize('response', naCount)}`,
            position: 'bottom'
          }
        }
}
    }
  }


}
