import {colors} from './colors'
import {helpers} from "./helpers";

export default function emailsAmount (qualtricsData) {
  const responses = qualtricsData.find(i => i.name === 'numberOfEmails')
  return {
    canvasId: 'emailsAmount',
    chart: {
      type: 'horizontalBar',
      data: {
        labels: ['Students', 'Faculty', 'Staff'],
        datasets: [
          {
            label: 'Reasonable:Unreasonable',
            data: [
              helpers.bipolarize(responses.values[0], 'student'),
              helpers.bipolarize(responses.values[0], 'faculty'),
              helpers.bipolarize(responses.values[0], 'staff'),
            ],
            backgroundColor: [
              colors.fuchsia.color,
              colors.mint.color,
              colors.purple.color,
            ],
            borderWidth: 1
          }
        ]
      },
      options: helpers.bipolarOptions(
        {
          right: 'Unreasonable',
          left: 'Reasonable',
          middle: 'Neutral'
        }
      )
    }
  }


}
