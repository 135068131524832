import {colors} from './colors'

export default function appUsage (qualtricsData) {
  const data  = {
                labels: ['Zoom', 'Microsoft Outlook', 'Slack', 'Messages', 'Mail'],
                data: [6380, 5921, 1480, 621, 272]}
  //qualtricsData.find(i => i.name = 'read-sent-ratio')
  return {
    canvasId: 'appUsage',
    chart: {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [{
          label: 'Mac App Usage (Employees)',
          data: data.data,
          backgroundColor: [
            colors.fuchsia.color,
            colors.mint.color,
            colors.purple.color,
            colors.neutral.color,
            colors.neutral.dark,
          ],
          borderColor: [
            colors.fuchsia.color,
            colors.mint.color,
            colors.purple.color,
            colors.neutral.color,
            colors.neutral.dark,
          ],
          borderWidth: 1
        }]
      },
      options: {
        aspectRatio: 1.2,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback(value, index, values) {
                return value === 0 ? 0 : value.toString().slice(0,-3) + 'k'
              },
            }
          }]
        }
      }
    }
  }


}
