import {colors} from './colors'

export default function insideDavidsonChart (qualtricsData) {
  return {
    canvasId: 'insideDavidsonChart',
    chart: {
      type: 'bar',
      data: {
        labels: ['HR', 'Committees', 'Students', 'Faculty Information', 'Search'],
        datasets: [{
          label: 'Page Views of InsideDavidson',
          data: [30321, 25395, 18587, 14846, 14787],
          backgroundColor: [
            colors.fuchsia.color,
            colors.mint.color,
            colors.purple.color,
            colors.neutral.color,
            colors.neutral.dark,
          ],
          borderColor: [
            colors.fuchsia.color,
            colors.mint.color,
            colors.purple.color,
            colors.neutral.color,
            colors.neutral.dark,
          ],
          borderWidth: 1
        }]
      },
      options: {
        aspectRatio: 1.2,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              labelString: 'Page Views',
              display: true,
            },
            ticks: {
              beginAtZero: true,
              callback(value, index, values) {
                return value === 0 ? 0 : value.toString().slice(0,-3) + 'k'
              },
            }
          }]
        }
      }
    }
  }


}
