export const helpers = {
  pluralize: (string, value) => {
    return value !== 1 ? string + 's' : string
  },
  bipolarNaCount: (values, persona = null) => {
    let count = 0
    if (persona) {
      count = values.values.find(i => i.id === '5').persona_breakdown[persona]
    } else {
      count = values.values.find(i => i.id === '5').count
    }
    return count
  },
  bipolarCount: (values, persona) => {
    let count = 0
    for (const value of values.values) {
      if (value.id !== '5') {
        count += value.persona_breakdown[persona]
      }
    }
    return count
  },
  bipolarize: (values, persona = null) => {
    const positive = {
      accumulator: 0,
      count: 0
    }
    const negative = {
      accumulator: 0,
      count: 0
    }
    for (const value of values.values) {
      const int = parseInt(value.id, 10)
      const count = persona ? value.persona_breakdown[persona] : value.count
      if (count === 0) {
        continue
      } else if (int > 2) {
        const norm = int === 3 ? 1.5 : 3
        positive.accumulator += (norm * count)
        positive.count += count
      } else {
        const norm = int === 1 ? -3 : -1.5
        negative.accumulator += (norm * count)
        negative.count += count
      }
    }
    const totalCount = positive.count + negative.count
    return [(negative.accumulator / totalCount).toFixed(2), (positive.accumulator / totalCount).toFixed(2)]
  },
  bipolarOptions: (labels, legend = null) => {
    return {
      legend: legend ? legend : { display: false },
      aspectRatio: 1.5,
      scales: {
        xAxes: [
          {
            ticks: {
              callback(value, index, values) {
                if (value === 2) {
                  return labels.right
                } else if (value === -2) {
                  return labels.left
                } else if (value === 0) {
                  return labels.middle
                } else {
                  ''
                }
              },
              min: -3,
              max: 3,
              precision: 1
            },
            gridLines: {
              drawBorder: false,
              offsetGridLines: false,
              beginAtZero: true,
              zeroLineColor: '#fff',
              color: '#fff'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      }
    }
  }
}
