import {colors} from './colors'

export default function toolActivityUsage (qualtricsData, persona = '') {
  const responses = qualtricsData.find(i => i.name === 'commToolActivityUse')
  const labels = responses.values.map(i => {
    switch (i.sub_id) {
      case '1':
        return 'Question'
      case '2':
        return 'Make Plans'
      case '3':
        return 'Meetings'
      case '4':
        return 'Events'
      case '5':
        return 'Inform about safety'
      case '6':
        return 'Share File'
      case '7':
        return 'Inform about life'
      case '8':
        return 'Get Help'
      case '9':
        return 'Lost & Found'
      case '10':
        return 'Socialize'
      default:
        return ''
    }
  })
  return {
    canvasId: `toolActivityUsage${persona}`,
    chart: {
      type: 'radar',
      data: {
        labels,
        datasets: [
          {
            label: 'Email',
            data: persona !== '' ? responses.values.map(i => i.values.find(j => j.id === '1').persona_breakdown[persona]) : responses.values.map(i => i.values.find(j => j.id === '1').count),
            backgroundColor: colors.fuchsia.transparent,
            borderColor: colors.fuchsia.color,
            borderWidth: 1
          },
          {
            label: 'Chat',
            data: persona !== '' ? responses.values.map(i => i.values.find(j => j.id === '2').persona_breakdown[persona]) : responses.values.map(i => i.values.find(j => j.id === '2').count),
            backgroundColor: colors.mint.transparent,
            borderColor: colors.mint.color,
            borderWidth: 1
          },
          {
            label: 'In-Person',
            data: persona !== '' ? responses.values.map(i => i.values.find(j => j.id === '3').persona_breakdown[persona]) : responses.values.map(i => i.values.find(j => j.id === '3').count),
            backgroundColor: colors.purple.transparent,
            borderColor: colors.purple.color,
            borderWidth: 1
          },
          {
            label: 'N/A',
            data: persona !== '' ? responses.values.map(i => i.values.find(j => j.id === '4').persona_breakdown[persona]) : responses.values.map(i => i.values.find(j => j.id === '4').count),
            backgroundColor: colors.neutral.transparent,
            borderColor: colors.neutral.color,
            borderWidth: 1
          }
        ]
      },
      options: {
        aspectRatio: 1,
        scale: {
          pointLabels: {
            fontSize: 14
          },
          ticks: {
            display: false
          },
          gridLines: {
            color: '#fff'
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false
            }
          }],
          xAxes: [{
            ticks: {
              display: false
            }
          }]
        }
      }
    }
  }


}
