import * as pattern from 'patternomaly';

// https://github.com/ashiguruma/patternomaly

export const colors = {
  red: {
    color: 'rgba(236, 129, 144, 1)',
    dark: 'rgba(172, 26, 47, 1)',
    transparent: 'rgba(236, 129, 144, 0.6)',
    pattern: pattern.draw('triangle-inverted', '#EC8190')
  },
  mint: {
    color: 'rgba(193, 246, 238, 1)',
    dark: 'rgba(26, 172, 151, 1)',
    transparent: 'rgba(193, 246, 238, 0.6)',
    darkTransparent: 'rgba(26, 172, 151, 0.6)',
    pattern: pattern.draw('zigzag-vertical', '#C1F6EE')
  },
  purple: {
    color: 'rgba(78, 26, 172, 1)',
    dark: 'rgb(51,20,102)',
    transparent: 'rgba(78, 26, 172, 0.6)',
    pattern: pattern.draw('diamond-box', '#4E1AAC')
  },
  fuchsia: {
    color: 'rgba(239, 232, 252, 1)',
    dark: 'rgba(166, 11, 69, 1)',
    transparent: 'rgba(239, 232, 252, 0.6)',
    darkTransparent: 'rgba(166, 11, 69, 0.6)',
    pattern: pattern.draw('diamond', '#EFE8FC')
  },
  neutral: {
    color: 'rgba(169, 183, 182, 1)',
    dark: 'rgba(69, 111, 121, 1)',
    transparent: 'rgba(169, 183, 182, 0.6)',
    pattern: pattern.draw('weave', '#A9B7B6')
  }
}
