import {colors} from './colors'

export default function tooManyEmailsSource (qualtricsData) {
  const responses = qualtricsData.find(i => i.name === 'tooManyEmailSource')
  const cleaned = responses.values.map(i => {
    return {
      label: i.label,
      total: i.count,
      student: i.persona_breakdown.student,
      staff: i.persona_breakdown.staff,
      faculty: i.persona_breakdown.faculty,
    }
  })
  const sorted = cleaned.sort((a, b) => {
    return a.total === b.total
      ? a.label > b.label ? -1 : 1
      : a.total > b.total ? -1 : 1
  })
  return {
    canvasId: 'tooManyEmailsSource',
    chart: {
      type: 'horizontalBar',
      data: {
        labels: sorted.map(i => i.label),
        datasets: [
          {
            label: 'Students',
            data: sorted.map(i => i.student),
            backgroundColor: colors.fuchsia.transparent,
            borderColor: colors.fuchsia.color,
            borderWidth: 1
          },
          {
            label: 'Faculty',
            data: sorted.map(i => i.faculty),
            backgroundColor: colors.mint.transparent,
            borderColor: colors.mint.color,
            borderWidth: 1
          },
          {
            label: 'Staff',
            data: sorted.map(i => i.staff),
            backgroundColor: colors.purple.transparent,
            borderColor: colors.purple.color,
            borderWidth: 1
          }
        ]
      },
      options: {
        aspectRatio: 1,
        scales: {
          yAxes: [{
            stacked: true
          }],
          xAxes: [{
            ticks: {
              display: false
            },
            stacked: true
          }]
        }
      }
    }
  }


}
