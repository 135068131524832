import {colors} from './colors'

export default function surveyResponses (qualtricsData) {
  const responses = qualtricsData.find(i => i.name === 'personaQuestion')
  return {
    canvasId: 'surveyResponses',
    chart: {
      type: 'horizontalBar',
      data: {
        labels: responses.values.map(i => i.label),
        datasets: [
          {
            label: 'Survey Responses',
            data: responses.values.map(i => i.count),
            backgroundColor: [
              colors.fuchsia.color,
              colors.purple.color,
              colors.mint.color
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        aspectRatio: 1.5,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
            }
          }]
        }
      }
    }
  }


}
